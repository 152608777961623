import React from 'react';
import Layout from '../../layout/Layout'
import {connect} from "react-redux";

import './Login.css'

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import AccountCircle from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import WarningIcon from '@material-ui/icons/Warning';

import {logOutUser} from '../../redux/actions/authActions'


const Login = ({loginError, logOutUser}) => {

    let pageTitle = "Bem-vindo ao Sistema Apollo Lunar";
    let mensagens = [
      "Será necessária uma conta do @patria.com.",
      "Caso possua, favor identifique-se no botão abaixo."
    ];

    if(loginError){
      switch (loginError.message) {
        case 'Request failed with status code 401':
          pageTitle = "Acesso Não Autorizado 401"
          mensagens = [            
            "Aparentemente sua conta não está cadastrada no Sistema Apollo Lunar",
            'Favor, solicitar o seu cadastro para "ti@vbirealestate.com"',
            'Se já está cadastrado, clicar em "sair" (abaixo) e tentar novamente',
            "Se o erro persistir, favor entrar em contato com o suporte do Sistema"
          ];
          break;
        default:
          pageTitle = "Erro inesperado no Login."
          mensagens = [
            'Ocorreu um erro inesperado no seu login!',
            'Talvez sua internet tenha caído durante este processo.',
            'Favor, clicar em "sair" e tentar novamente (com a sua conta do @vbirealestate.com).',
            'Se mesmo assim o problema persistir, entre em contato com o Suporte do Sistema.',
          ];    
      }
    }


    const GOOGLE_AUTH_LINK = process.env.REACT_APP_SERVER_URL + "/auth/"

    function onLogOut(event) {
      event.preventDefault(); //(obs: In React you cannot user "return false" to prevent default behavior. You must call preventDefault explicitly.) [https://reactjs.org/docs/handling-events.html]
      logOutUser();
    }    

    return(
      
        <Layout pageTitle={pageTitle}>
          <Paper id="login" elevation={2}>
            {!loginError? (
              <AccountCircle fontSize="large" className="loginIcon"/>
            ) : (
              <WarningIcon fontSize="large" className="loginIcon"/>
            )}

            <div className="descricaoContainer">
              {mensagens.map((mensagem)=> <p className="lunar textoDescritivo"> {mensagem} </p>)}
            </div>
            <div className="horizontalLine"> </div>

            {!loginError? (
              <Button href={GOOGLE_AUTH_LINK} variant="contained" color="primary">
                <ExitToAppIcon/> &nbsp;  LOGIN COM CONTA DO PATRIA
              </Button>
            ) : (
              <Button onClick={onLogOut} variant="contained" color="primary">
                <ExitToAppIcon/> &nbsp;  SAIR
              </Button>
            )}

          </Paper>
        </Layout>
    )
  
  }

const mapStateToProps = (state) => ({
  loginError: state.auth.loginError,
});  

export default connect(mapStateToProps, {logOutUser})(Login)
